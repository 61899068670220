import { Event } from '@bugsnag/core'
import Bugsnag from '@bugsnag/js'
import BugsnagVue from '@bugsnag/plugin-vue'
import Vue from 'vue'
import {
  bugsnagIgnoreErrorList,
  bugsnagIgnoreSourceList,
} from './bugsnag-ignore'

const appVersion = import.meta.env.VITE_APP_BUILD_VERSION || 'last'

export function onErrorHandler(event: Event) {
  console.error(event.errors)

  if (
    bugsnagIgnoreErrorList.some((re) =>
      new RegExp(re).test(event.errors[0].errorMessage)
    )
  )
    return false

  if (
    bugsnagIgnoreSourceList.some((str) =>
      new RegExp(str).test(event.errors[0].stacktrace[0].file)
    )
  )
    return false

  event.setUser(eonx.user?.externalId)
  event.addMetadata('provider', {
    subdomain: eonx.provider.subdomain,
  })
}

if (!import.meta.env.DEV) {
  Bugsnag.start({
    apiKey: eonx.keys.bugsnag,
    redactedKeys: [
      'verificationCode',
      'expiryMonth',
      'expiryYear',
      'cardholder',
      'number',
      /^password$/i,
    ],
    appVersion: appVersion,
    plugins: [new BugsnagVue(Vue)],
    releaseStage: eonx.env ?? undefined,
    onError: onErrorHandler,
  })
}

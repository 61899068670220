export const bugsnagIgnoreErrorList = [
  'Unauthorized',
  'Network *Error',
  'ResizeObserver loop limit exceeded',
  'Loading.+chunk.+failed',
  'Importing a module script failed',
  'error loading dynamically imported module',
  'Fetch is aborted',
  'Navigation cancelled from',
  'Card verification failed',
  '3D Secure verification failed. Please contact your bank',
  'There was a problem processing your payment. Please try again later',
  'Unable to preload CSS for',
  'Card verification cancelled',
  'Extension type not found',
]

export const bugsnagIgnoreSourceList = [
  '/node_modules/',
  'googleapis.com/',
  'webkit-masked-url',
  'cdn.pmnts.io/',
]
